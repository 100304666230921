import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';

class InitFirebase {

    private config  = {
        apiKey: "AIzaSyAKL50ZkHN1dK3KCw88LLaLrav6WaDULVI",
        authDomain: "e-kelurahan-8d307.firebaseapp.com",
        databaseURL: "https://e-kelurahan-8d307.firebaseio.com",
        projectId: "e-kelurahan-8d307",
        storageBucket: "e-kelurahan-8d307.appspot.com",
        messagingSenderId: "1067847337612",
        appId: "1:1067847337612:web:f764dd78291993f0531da9"
    };

    constructor() {

        if (!firebase.apps.length) {
            firebase.initializeApp(this.config)
        }
    }

    public auth()  {

        return firebase.auth()
    }

    public storage()  {

     return firebase.storage()
    }

    public firestore()  {

        return firebase.firestore()
    }

    public functions() {

        return firebase.functions()
    }

}

export default InitFirebase;
