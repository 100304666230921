import React, {useEffect, useState, useContext} from "react";
import {Link} from "react-router-dom";
import {Layout, Menu, Button} from 'antd';
import {
    PicLeftOutlined,
    PicRightOutlined,
    SettingFilled,
    EditOutlined,
    FundProjectionScreenOutlined,
    UserOutlined,
    ExceptionOutlined
} from '@ant-design/icons';
import {Auth} from "modules";

// style
import styles from './sider.module.css'

const Sider: React.FC = () => {

    const [rootSubMenuKeys, ]               = useState<string[]>(['setting', 'request']);
    const [openKeys, setOpenKeys]           = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys]   = useState<string>('');
    const [collapse, setCollapse]           = useState<boolean>(false);
    const [userLevel,]                      = useState<number[]>([1, 2, 3])

    const {user} = useContext(Auth.AuthContext);

    useEffect(() => {

        let pathname : string = window.location.pathname;

        setSelectedKeys(pathname);

        setOpenKeys([pathname.split("/")[2]]);

    }, []);

    const onBreakpoint = (broken: boolean): void => {

        setCollapse(broken)
    };

    const onTrigger = (): void => {

        setCollapse(prevState => !prevState)
    };

    const onOpenChange = (keys : string[]) : void => {

        const latestOpenKey : string | undefined = keys.find(key => openKeys.indexOf(key) === -1);

        if (latestOpenKey){

            if (rootSubMenuKeys.indexOf(latestOpenKey) === -1) {

                setOpenKeys(openKeys)
            }else{

                setOpenKeys( [latestOpenKey])
            }
        }else{

            setOpenKeys( [])
        }
    };

    return (
        <Layout.Sider
            collapsed={collapse}
            breakpoint={"lg"}
            collapsedWidth={0}
            className={styles.sider}
            width={'250'}
            trigger={null}
            onBreakpoint={onBreakpoint}
        >
            <div className={styles.buttonCollapse}>
                <Button shape={'circle'} size={'large'} onClick={onTrigger}>
                    {!collapse ? <PicLeftOutlined/> : <PicRightOutlined/>}
                </Button>
            </div>

            <div className={styles.logo} >
                <img width={100} src={"/logo.png"} alt={"logo"}/>
                <p>
                    <strong>Desa Batukaropa</strong>
                </p>
            </div>

                {
                    userLevel.includes(user.claims?.userLevel)
                        ? (
                        <Menu
                            theme="light"
                            mode="inline"
                            selectedKeys={[selectedKeys]}
                            openKeys={openKeys}
                            onOpenChange={onOpenChange}
                        >

                            <Menu.Item>
                                <h4>Main menu</h4>
                            </Menu.Item>

                            <Menu.Item key="/manage/activity">
                                <Link to={'/manage/activity'}>
                                    <FundProjectionScreenOutlined />
                                    <span className="nav-text">Kegiatan/Berita</span>
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="/manage/users">
                                <Link to={'/manage/users'}>
                                    <UserOutlined />
                                    <span className="nav-text">Pengguna</span>
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="/manage/services">
                                <Link to={'/manage/services'}>
                                    <EditOutlined />
                                    <span className="nav-text">Pelayanan</span>
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="/manage/complaint">
                                <Link to={'/manage/complaint'}>
                                    <ExceptionOutlined />
                                    <span className="nav-text">Pengaduan</span>
                                </Link>
                            </Menu.Item>

                            {/*<Menu.SubMenu*/}
                            {/*    key={'request'}*/}
                            {/*    title={*/}
                            {/*        <span>*/}
                            {/*            <EditOutlined />*/}
                            {/*            <span>Permohonan</span>*/}
                            {/*        </span>*/}
                            {/*    }*/}
                            {/*>*/}

                            {/*    <Menu.Item key="/manage/request/meeting-agenda">*/}
                            {/*        <Link to={'/manage/request/meeting-agenda'}>*/}
                            {/*            <span className="nav-text">Agenda Rapat</span>*/}
                            {/*        </Link>*/}
                            {/*    </Menu.Item>*/}

                            {/*    <Menu.Item key="/manage/meeting/meeting-minutes">*/}
                            {/*        <Link to={'/manage/meeting/meeting-minutes'}>*/}
                            {/*            <span className="nav-text">Notula Rapat</span>*/}
                            {/*        </Link>*/}
                            {/*    </Menu.Item>*/}

                            {/*</Menu.SubMenu>*/}

                            <Menu.SubMenu
                                key={'setting'}
                                title={
                                    <span>
                                      <SettingFilled/>
                                      <span>Setelan</span>
                                    </span>
                                }
                            >
                                <Menu.Item key="/manage/setting/account">
                                    <Link to={'/manage/setting/account'}>
                                        <span className="nav-text">Profil</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="/manage/setting/village-profile">
                                    <Link to={'/manage/setting/village-profile'}>
                                        <span className="nav-text">Profil Kelurahan</span>
                                    </Link>
                                </Menu.Item>

                                {/*<Menu.Item key="/manage/setting/users">*/}
                                {/*    <Link to={'/manage/setting/users'}>*/}
                                {/*        <span className="nav-text">Anggota</span>*/}
                                {/*    </Link>*/}
                                {/*</Menu.Item>*/}

                            </Menu.SubMenu>
                        </Menu>
                    ) : (
                        <Menu
                            theme="dark"
                            mode="inline"
                            selectedKeys={[selectedKeys]}
                            openKeys={openKeys}
                            onOpenChange={onOpenChange}
                        >

                            <Menu.Item>
                                <h4>Main menu</h4>
                            </Menu.Item>

                            <Menu.Item key="/manage/start-exam">
                                <Link to={'/manage/start-exam'}>
                                    <EditOutlined/>
                                    <span className="nav-text">Exams</span>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    )
                }
        </Layout.Sider>
    )
};

export default Sider;
