
// convert snapshot to array
export const parseSnapshot = (snapshot : any) : any[] => {

    let arr : any[] = [];

    snapshot.forEach((doc : any) => {

        if (doc.data().timestamp) {

            arr.push({...doc.data(), key : doc.id, timestamp : doc.data().timestamp?.toDate()})
        } else if (doc.data().datetime) {

            arr.push({...doc.data(), key : doc.id, datetime : doc.data().datetime?.toDate()})
        } else {

            arr.push({...doc.data(), key : doc.id})
        }
    });

    return arr;
};

// remove duplicate contact
// export const removeDuplicate = ( newContact : object[], listContact : any[]) : any[] => {
//     return newContact
//         .filter((item: any) => {
//             return listContact.map((contact) => contact.phoneNumber).indexOf(item.phoneNumber) === -1;
//     });
// };

// delay
export const sleep = (time : number) : Promise<string> => {
    return new Promise(resolve => {
        setTimeout(() => {
            return resolve();
        }, time)
    })
};

/* shor vertical  */
export const shortVertical = (arr : any [], size : number) : any[] => {

    const chunk = (arr : any[], size : number) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
            arr.slice(i * size, i * size + size)
        );

    let parse = chunk(arr, size);

    let newData = [];

    for (let i = 0; i < size; i++) {
        for (let j = 0; j < arr.length / size; j++) {
            newData.push(parse[j][i]);
        }
    }

    return newData;
};

// paginate array
export const paginate = (array : any[], page_size : number, page_number : number) : any[] => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const move = (arr : any[], old_index : number, new_index : number) : any[] => {

    while (old_index < 0) {
        old_index += arr.length;
    }

    while (new_index < 0) {
        new_index += arr.length;
    }

    if (new_index >= arr.length) {
        var k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }

    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    return arr;
}