import InitFirebase from './init.firebase';
import firebase from "firebase/app";
import {parseSnapshot} from "utils";

class FirestoreFirebase extends InitFirebase {

    public create(collection : string, data : object) : Promise<string> {

        return this.firestore()
            .collection(collection)
            .add(data)
            .then(() => Promise.resolve("Data berhasil ditambakan."))
            .catch(error => Promise.reject(error.message))
    }

    public read(collection : string) : Promise<any[] | string> {
        return this.firestore()
            .collection(collection)
            .get()
            .then((snapshot : firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
                let data : any[] = parseSnapshot(snapshot);
                return Promise.resolve(data);
            })
            .catch(error => Promise.reject(error.message))
    }

    public update(collection : string, document : string, data : object) : Promise<string> {
        return this.firestore()
            .collection(collection)
            .doc(document)
            .update(data)
            .then(() => Promise.resolve("Data berhasil diperbarui."))
            .catch(error => Promise.reject(error.message))
    }

    public delete(collection : string, document : string) : Promise<string> {
        return this.firestore()
            .collection(collection)
            .doc(document)
            .delete()
            .then(() => Promise.resolve("Data berhasil dihapus."))
            .catch(error => Promise.reject(error.message))
    }

}

export default FirestoreFirebase
