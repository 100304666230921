import React, {useContext} from 'react';
import {Layout, Avatar, Popover} from 'antd';
import {AuthFirebase} from "services";
import {Auth} from "modules";

import styles from './header.module.css'

import PooperContent from "./pooper.header";

const Header: React.FC = () => {

    const {user} = useContext(Auth.AuthContext);

    const signOut = async (): Promise<void> => {
        try {

            const auth: any = new AuthFirebase();

            await auth.siginOut();

        } catch (e) {

            console.log(e);
        }
    };

    return (
        <Layout.Header className={styles.header}>
            <div className={styles.layoutHeader}>
                <ul>
                    <li className={styles.inline}>
                        <Popover
                            placement="bottomRight"
                            trigger="click"
                            title={null}
                            content={
                                <PooperContent
                                    displayName={user.claims.nama}
                                    email={user.claims.noKK}
                                    photoURL={user.claims.photo}
                                    signOut={signOut}
                                />
                            }
                        >
                            <Avatar
                                style={{cursor: 'pointer'}}
                                src={user.claims.photo || 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'}
                                size={'large'}
                            />
                        </Popover>
                    </li>
                </ul>
            </div>
        </Layout.Header>
    )
};

export default React.memo(Header);
