import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {Spinner} from "components";
import {Auth} from "modules";

// component
import AuthRoute from "./auth.routes";

// pages
const Login          : React.FC = React.lazy(() => import('../pages/login'));
const Activity       : React.FC = React.lazy(() => import('../pages/activity'));
const Users          : React.FC = React.lazy(() => import('../pages/users'));
const Service        : React.FC = React.lazy(() => import('../pages/service'));
const Complaint      : React.FC = React.lazy(() => import('../pages/complaint'));
const Account        : React.FC = React.lazy(() => import('../pages/account'));
const VillageProfile : React.FC = React.lazy(() => import('../pages/villageProfile'));


const Router : React.FC = () => {
    return (
        <Auth.AuthProvider>
            <React.Suspense fallback={<Spinner tip={'fetching component ...'} spinning={true} />}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path={'/'}                               component={Login}          />
                        <Route       path={'/login'}                          component={Login}          />
                        <AuthRoute   path={'/manage/activity'}                component={Activity}       />
                        <AuthRoute   path={'/manage/services'}                component={Service}        />
                        <AuthRoute   path={'/manage/complaint'}               component={Complaint}      />
                        <AuthRoute   path={'/manage/users'}                   component={Users}          />
                        <AuthRoute   path={'/manage/setting/account'}         component={Account}        />
                        <AuthRoute   path={'/manage/setting/village-profile'} component={VillageProfile} />
                    </Switch>
                </BrowserRouter>
            </React.Suspense>
        </Auth.AuthProvider>
    )
};

export default Router;
